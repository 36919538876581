import Header from './components/Header'
import Home from './components/Home'
import About from './components/About'
import Editing from './components/Editing'
import Comedy from './components/Comedy'
import Articles from './components/Articles'



function App() {
  return (

    <>
    < Header />
    < Home />
    < About />
    < Comedy />
    < Editing />
    < Articles />
    </>

  )}


export default App;

