import React from 'react'
import blogHER from "./blogHER.jpeg"

 

const Comedy = () => {
    return (
        <div id = "comedy">
        <br></br>
        <br></br>   
        <br></br>   
        <br></br> 

                <h1 style = {{textAlign: 'center'}}> Comedy</h1>
                <br></br>
                <br></br>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20, marginLeft: '5%', columnGap: "40px"}}>
                <div id = "topimage">
                    <img src={"https://m.media-amazon.com/images/I/5191mj3wjVL.jpg"} style = {{width: "300px", height: "350px", alignItems: 'center'}} className = "lipstick"/>
                    <br></br>
                    <br></br>
                    I Just Want to Pee Alone
                    <br></br>
                    Motherhood is the toughest – and funniest – job you'll ever love. <br></br>
                    Buy it <a href = "https://www.amazon.com/I-Just-Want-Pee-Alone/dp/0988408031/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1641337735&sr=1-" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a> 
                </div>
                
                <div id = "lipstick">
                    <div id = "image">
                    <img src={"https://images-na.ssl-images-amazon.com/images/I/71fj6Og6oxL.jpg"} style = {{width: "340px", height: "340px", alignItems: 'center'}} />
                    </div>
                    <br></br>
                    <br></br>
                    You Have Lipstick on Your Teeth
                    <br></br>
                    And other things you’ll only hear from your friends in the powder room. <br></br>
                    Buy it <a href = "https://www.amazon.com/You-Have-Lipstick-Your-Teeth/dp/1490963413" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a> 

                </div>

                <div>
                    <img src={blogHER} style = {{width: "340px", height: "340px", alignItems: 'center'}}/>
                    <br></br>
                    <br></br>
                    The Loudest Family in America
                    <br></br>
                    BlogHER 2013 Award Winning Essay <br></br>
                    Read it <a href = "https://randomhandprints.blogspot.com/2014/02/id-like-to-thank-academy.html?m=1" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a> 
                </div>
            </div>

<br></br>
        </div>
    )
}

export default Comedy