import React from 'react'
import "./Articles.css";
import huffpost from "./huffpost.png"
import barista from "./baristanet.png"
import westfield from "./westfield.png"
 

const Articles = () => {
    return (
        < div id = "articles">
<br></br>
<br></br>   
<br></br>
<br></br>             
        <h1> Articles </h1>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20, marginLeft: '5%', columnGap: "40px"}}>
                <div>
                    <img src={huffpost} style = {{width: "400px", height: "200px", alignItems: 'center'}}/>
                    <br></br>
                    <br></br>
                    The Huffington Post
                    <br></br>
                    <br></br>
                    I was a contributing writer at The Huffington Post, where I shared hacks for lots of things, but went viral for sharing how to wrap gifts without any tape. Or scissors.
                    Read my work <a href = "https://www.huffpost.com/author/anna-sandler" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>
                </div>
                
                <div>
                    <img src={barista} style = {{width: "400px", height: "200px", alignItems: 'center'}} />
                    <br></br>
                    <br></br>
                    Baristanet
                    <br></br>
                    <br></br>
                    This hyperlocal news site focuses primarily on Montclair, but with some local coverage of Maplewood as well, which was my usual beat. 
                    I also wrote reviews of new restaurants, books, and performances at SOPAC.
                    Read my work <a href = "https://baristanet.com/writer/anna-sandler/" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>

                </div>

                <div>
                    <img src={westfield} style = {{width: "400px", height: "200px", alignItems: 'center'}}/>
                    <br></br>
                    <br></br>
                    Wonders of Westfield
                    <br></br>
                    <br></br>
                    Covering all things Westfield, I found the best places to eat, drink, people watch, or take your rambunctious toddler.
                    Visit their Facebook page <a href = "https://www.facebook.com/WondersOfWestfield/" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>
                </div>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
}

export default Articles