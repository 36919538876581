import React from 'react'
import anna from "./anna.png"
import fred from "./fred.png"


const Home = () => {
    return (
        <div id = "home">
            <h1 style = {{textAlign: 'center'}}>
                Anna Sandler </h1>
            <h2 style = {{textAlign: 'center'}}>author ~ freelance writer ~ editor</h2>
            <br></br>
            <div id = 'photos'>
            <img src = {anna} style = {{width: '580px', height:'500px', textAlign: 'center'}}></img>
            <img src = {fred} style = {{width: '580px', height:'500px', textAlign: 'center'}}></img>
            </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        </div>
    )
}

export default Home
