import React from 'react'
import "./About.css";
import insta from "./insta.png"
import twitter from "./twitter.png"
import linkedin from "./linkedin.png"
import facebook from "./facebook.png"
import mom from "./mom.png"
import { Link } from 'react-scroll';

const About = () => {
    return (
        <div id = "about">
            <h1 style = {{textAlign: 'left', marginLeft: "20%"}}> About Me </h1>
            <div className = "content" style = {{display: 'inline-flex'}}>
            <p>
                Anna Sandler is a longtime resident of scenic New Jersey, but a New Yorker in her heart. 
                A graduate of Wesleyan University and the Simmons Graduate School of Management, Anna has been writing online since 1996 for a variety of publications including The Huffington Post and New Jersey Family magazine, where she was a contributing editor.
                In addition to covering the local news as a reporter an editor for<a href = {"https://www.tapinto.net/towns/soma/"} target="_blank" style = {{textDecoration: 'underline'}}>TAPintoSOMA.com</a>for many years, she was a partner at Sandler and Wald Social Media, 
                a consulting firm with a focus on helping small businesses navigate the online space, and a chronicler of modern motherhood and mayhem on her humor blog,<a href = "http://randomhandprints.blogspot.com/" target="_blank" style = {{textDecoration: 'underline'}}> RandomHandprints.com </a>
                
                Anna received national coverage from publications including the New York Post, ABC News and the Associated Press for her efforts to ease the gas crisis during Hurricane Sandy via the Twitter account @NJGas.
            </p>
        
        <div className = "momPhoto" style= {{marginLeft: '10%', marginTop: '1.5%', width: '600px', height: '400px'}}> <img src = {mom} /> </div>
        </div>
        <div id = "links">
            <a href= {"https://www.instagram.com/whyisanna/"} target="_blank"><img src={insta} style = {{height: '50px', width: '50px'}} /> </a>
            <a href= {"https://twitter.com/anna_sandler"} target="_blank"><img src={twitter} style = {{height: '50px', width: '50px'}} /> </a>
            <a href= {"https://www.facebook.com/RandomHandprints/"} target="_blank"><img src={facebook} style = {{height: '50px', width: '50px'}} /> </a>
            <a href= {"https://www.linkedin.com/in/anna-sandler-92b614/"} target="_blank"><img src={linkedin} style = {{height: '50px', width: '50px'}} /> </a>
        </div>
        <br></br>
        </div>
    )
}

export default About