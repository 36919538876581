import React from 'react'
import "./Header.css";
import { Link } from 'react-scroll';

const Header = () => {
    return (
        <div>
        <header className = "header">
        
        <ul className='nav-item'>
        <Link
            to='home'
            className='nav-links'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
                HOME
         </Link>
        </ul>

        <ul className='nav-item'>
        <Link
            to='about'
            className='nav-links'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
                ABOUT
         </Link>
        </ul> 
        
        <ul className='nav-item'>
        <Link
            to='comedy'
            className='nav-links'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
                COMEDY
         </Link>
        </ul> 
                  
        <ul className='nav-item'>
        <Link
            to='editing'
            className='nav-links'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
                EDITING
         </Link>
        </ul>

        <ul className='nav-item'>
        <Link
            to='articles'
            className='nav-links'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
                ARTICLES
         </Link>
        </ul>

        </header>
        </div>
    )
}

export default Header