import React from 'react'
import taplogo from "./taplogo.png"
import RHButton from "./RHButton.jpg"
import njfamily from "./njfamily.png"

 

const Editing = () => {
    return (
        <div id = "editing">
        <br></br>
        <br></br> 
        <br></br>
        <br></br>
            <h1> Editing</h1>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20, marginLeft: '5%', columnGap: "40px"}}>
                <div>
                    <img src={taplogo} style = {{width: "400px", height: "200px", alignItems: 'center'}}/>
                    <br></br>
                    <br></br>
                    TAPintoSOMA.net
                    <br></br>
                    <br></br>
                    I was the editor-in-chief of TAPintoSOMA, a hyper-local news site focusing on South Orange and Maplewood New Jersey from 2014 to 2018.

                    I wrote and edited more than 1,000 articles on everything from local politics, to dining and entertainment, to sports and recreation.
                    Read an example of my work at Tap <a href = "https://www.tapinto.net/sections/education/articles/november-snowstorm-in-soma-caused-epic-traffic-and-stranded-students-but-also-lots-of-acts-of-kindness" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>
                </div>
                
                <div>
                    <br></br>
                    <img src={RHButton} style = {{width: "360px", height: "180px", alignItems: 'center'}} />
                    <br></br>
                    <br></br>
                    RandomHandprints.com
                    <br></br>
                    <br></br>
                    One of the first of the “mommy bloggers,” I wrote first at Big City Mom and then once I moved to the suburbs at Random Handprints for more than a decade. 
                    The blog was voted one of the best NYC mom blogs and one of my posts was selected as a “Blogher Voice of the Year” in 2013. 
                    Visit Random Handprints <a href = "http://randomhandprints.blogspot.com/" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>.

                </div>

                <div>
                    <img src={njfamily} style = {{width: "400px", height: "200px", alignItems: 'center'}}/>
                    <br></br>
                    <br></br>
                    NJFamily.com
                    <br></br>
                    <br></br>
                    As a Contributing Editor and one of their “Real Moms of New Jersey” I covered crafts, baking and cooking with kids, parenting hacks, and where to go and what to do for families all over New Jersey, as well as making sure to share the latest and greatest cultural opportunities and day trips in New York City.
                    Read an example of my work at NJfamily <a href = "https://www.njfamily.com/5-tips-to-help-moms-and-dads-get-organized/" target="_blank" style = {{textDecoration: 'underline'}}>HERE</a>.
                </div>
            </div>

            <br></br>
        </div>
        
    )
}

export default Editing
